import { getMember } from './members.js'

export const getComments = async (forum)=>{
    const resp = await fetch(`/api/comments?forum=${forum}`)
    const json = await resp.json()
    const comments = await Promise.all(json.data.map(async (x) => {
        const member = await getMember(x.member)
        return ({...x, memberName:member.name})
    }))
    return comments
}

export const postComment = async ({member, forum, content, created}) => {

    const data = new FormData()
    data.append('member', member)
    data.append('forum', forum)
    data.append('content', content)
    data.append('created', created)

    const resp = postData(data)

    return resp
}

export const postData = async (data) => {

    const resp = await fetch('/api/comments', {
        method: 'POST',
        body: data
    })

    const json = await resp.json()
    return json.data
}
