import { Form
    , Container
    , Section
    , Heading
    , TextInput
    , CheckBoxInput
    , SaveButton
    , useRegistry } from "../components/utils.js"

export const Photo = ({member, container}) => {

    const board = document.createElement("div")
    board.className = "container"

    board.appendChild(Container([Section({left:Form(
        [ Heading("title", "Photo", "1")
        , TextInput("description", "Description", "")
        , TextInput("created", "Created", "", "date")
        , TextInput("photo", "Photo", "", "file")
        , CheckBoxInput("private", "Private", true)
        , SaveButton(getSave(member, container)) ])})]))
    
    return board
}

const postForm = async (member, container) => {
    const description = document.getElementById('description').value
    const created = document.getElementById('created').value
    const photo = document.getElementById('photo').files[0]
    const prvt = document.getElementById('private').checked

    var data = new FormData()
    data.append('description', description)
    data.append('created', created)
    data.append('photo', photo)
    data.append('private', prvt)
    data.append('member', member)
    data.append('container', container)

    const resp = await fetch('/api/photos', {
        method: 'POST',
        body: data
    })
    const json = await resp.json()
    
    if (json.status == 'NOK') throw json.message

    return json
}

const getSave = (member, container) => {
    const save = () => {
        const innerSave = async () => {
            try {
                const { dispatch } = useRegistry()
                dispatch("SPINNER_ON")    
                try {
                    await postForm(member, container)
                }
                finally {
                    dispatch("SPINNER_OFF")
                }    
            }
            catch(err) {
                alert(err)
            }
        }
        innerSave()
    }
    return save
}





