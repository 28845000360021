import { getAuthenticated, login, logout } from './auth.js'
import { redirect } from "../components/router.js"
import { Search, Auth } from "../components/utils.js"

import lambdaurl from '../../img/lambda.jpg'

const Lambda = () => {
    const img = document.createElement("img")
    img.alt="#"
    img.src=lambdaurl
    img.width=32
    img.height=32
    return img
}

const Brand = () => {
    const brand = document.createElement("a")
    brand.href="#"
    brand.className="navbar-brand"
    brand.onclick = ()=> redirect("home") 
    brand.appendChild(Lambda())

    return brand
}

export const Menu = () => {

    const authenticated = getAuthenticated()

    const header = document.createElement("header")

    const nav = document.createElement("nav")

    const container = Container()

    nav.appendChild(container)

    container.appendChild(Brand())

    const navtoggle = NavToggle()

    container.appendChild(navtoggle)

    const navcontent = NavContent()

    if (authenticated) {
        navcontent.appendChild(NavBar())
        navcontent.appendChild(Search(""))
    }
    else navcontent.appendChild(PushLoginMenuPlaceholder())

    const addLogin = () => {
        navcontent.appendChild(Auth(authenticated
            , () => {
                const l = async() => {
                    await login()
                    redirect('/')
                }
                l()
            } //"/.auth/login/aad?post_login_redirect_uri=/"
            , () => {
                const l = async() => {
                    await logout()
                    redirect('/')
                }
                l()
            } //"/.auth/logout"
            , ()=> redirect("settings")))        
    }
    
    addLogin()

    container.appendChild(navcontent)

    nav.className = "navbar navbar-expand-lg navbar-light bg-light"

    header.appendChild(nav)

    return header
}

const Container = () => {
    const container = document.createElement("div")
    container.className = "container-fluid"
    return container
}

const NavToggle = () => {
    const navtoggle = document.createElement("button")

    navtoggle.type = "button"
    navtoggle.className="navbar-toggler"
    navtoggle.setAttribute("data-bs-toggle", "collapse")
    navtoggle.setAttribute("data-bs-target", "#navbarSupportedContent")
    navtoggle.setAttribute("aria-controls", "navbarSupportedContent")
    navtoggle.setAttribute("aria-expanded", "false")
    navtoggle.setAttribute("aria-label", "Toggle navigation")

    const navtoggleicon = document.createElement("span")

    navtoggleicon.className="navbar-toggler-icon"

    navtoggle.appendChild(navtoggleicon)

    return navtoggle
}

const NavContent = () => {
    const navcontent = document.createElement("div")
    navcontent.id="navbarSupportedContent"
    navcontent.className="collapse navbar-collapse"

    return navcontent
}

const NavBar = () => {
    const navbar = document.createElement("ul")
    navbar.className="navbar-nav me-auto mb-2 mb-lg-0"
 
    navbar.appendChild(NavItem("Albums", "albums", true))
    navbar.appendChild(NavItem("Forums", "forums", true))
    navbar.appendChild(NavItem("Events", "events", true))
    navbar.appendChild(NavItem("Genealogy", "genealogy", true))
    
    //navbar.appendChild(NavItem("Organizations", "", false, DropDown("Organizations")))

    return navbar
}

const PushLoginMenuPlaceholder = () => {
    const div = document.createElement("div")
    div.className="me-auto"
    return div
}

const NavItem = (label, link, current, dropdown) => {
    const navitem = document.createElement("li")
    navitem.className=(dropdown) ? "nav-item dropdown" : "nav-item"
    
    const anchor = document.createElement("a")
    anchor.href = "#"
    if (link) anchor.onclick = (e) => { 
        e.preventDefault() 
        redirect(link) 
    } 
    anchor.innerText = label
    anchor.className="nav-link"
    anchor.className=(current) ? anchor.className + " active" : anchor.className
    anchor.className=(dropdown) ? anchor.className + " dropdown-toggle" : anchor.className
    if (current) anchor.setAttribute("aria-current", "page")
    if (dropdown) anchor.id="navbarDropdown" 
    if (dropdown) anchor.role="button" 
    if (dropdown) anchor.setAttribute("data-bs-toggle", "dropdown") 
    if (dropdown) anchor.setAttribute("aria-expanded", "false")

    if (dropdown) navitem.appendChild(dropdown)

    navitem.appendChild(anchor)
    return navitem
}

const DropDown = () => {
    const dropdown = document.createElement("ul")
    dropdown.className="dropdown-menu" 
    dropdown.setAttribute("aria-labelledby", "navbarDropdown")
    dropdown.appendChild(DropDownItem("Diagram"))
    dropdown.appendChild(DropDownItem("Attributes"))
    dropdown.appendChild(DropDownItem("-"))
    dropdown.appendChild(DropDownItem("Reconsiliation"))
    return dropdown
}

const DropDownItem = (label) => {
    const dropdownitem = document.createElement("li")
    const anchor = document.createElement((label==="-")? "hr" : "a")
    if (label==="-") anchor.href = "#"
    anchor.className= (label==="-")? "dropdown-devider" : "dropdown-item"
    anchor.innerText = (label==="-")? "" : label
    dropdownitem.appendChild(anchor)
    return dropdownitem
}
