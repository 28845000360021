import { redirect } from "../components/router.js"
import { getAccountId } from '../components/auth.js'
import { getMembers } from '../services/members.js'
import { Heading } from '../components/utils.js'

export const Albums = () => {
    
    const board = document.createElement('div')
    board.className = "container my-3"

    const heading = Heading("first-title", "Albums", "4")

    board.appendChild(heading)

    mountPillMenu().then((table)=> {
        board.appendChild(table)
    })

    return board
}

const CardMenu = (items) => {
    const el = document.createElement("div")
    el.className = "d-grid gap-2 col-8 mx-auto" //"d-flex align-items-center justify-content-center"

    items.forEach((item)=>el.appendChild(item))

    return el
}

const PillButton = (id, name) => {

    const el = document.createElement("button")
    el.id=id
    el.type="button"
    const btnColor = (id==getAccountId())? 'btn-success':'btn-primary'
    el.className=`btn btn-lg btn-xtl ${btnColor}`
    //enlarge the buttons for mobile
    //TODO: use css media query
    //el.setAttribute("style", "--bs-btn-padding-y: 1rem; --bs-btn-padding-x: 2rem; --bs-btn-font-size: 3rem;") 
    el.textContent = name
    el.addEventListener("click", (e)=> {
        e.preventDefault()
        redirect("album", {member:id, container:id, name}) //this code makes container name = member id 
    })

    return el
}

const Card = (id, name) => {

    const card = document.createElement("div")
    card.className="card"

    const body = document.createElement("div")
    body.className="card-body"

    const btnColor = (id==getAccountId())? 'btn-success':'btn-primary'
    const a = document.createElement("a")
    a.href="#"
    a.className=`d-inline bi bi-play btn ${btnColor}`
    a.addEventListener("click", (e)=> {
        e.preventDefault()
        redirect("album", {member:id, container:id, name}) //this code makes container name = member id 
    })
    
    const heading = Heading('title', name, '5')
    heading.className = "d-inline mx-2"

    body.appendChild(a)
    body.appendChild(heading)

    card.appendChild(body)

    return card
}

const mountPillMenu = async () => {
    const members = await getMembers()

    return CardMenu(members.map((m)=>Card(m.id, m.name)))

}