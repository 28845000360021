import { useRegistry } from "./utils.js"
import { Home } from "../pages/home.js"
import { Albums } from "../pages/albums.js"
import { Album } from "../pages/album.js"
import { Photo } from "../pages/photo.js"
import { Forum } from "../pages/forum.js"
import { Forums } from "../pages/forums.js"
import { Settings } from "../pages/settings.js"
import { Comments } from "../pages/comments.js"
import { Event } from "../pages/event.js"
import { Events } from "../pages/events.js"
import { Genealogy } from "../pages/genealogy.js"

const routes = new Map()

routes.set("/", ()=>{window.location.reload()})
routes.set("home", Home)
routes.set("albums", Albums)
routes.set("album", Album)
routes.set("photo", Photo)
routes.set("forum", Forum)
routes.set("forums", Forums)
routes.set("settings", Settings)
routes.set("event", Event)
routes.set("events", Events)
routes.set("comments", Comments)
routes.set("genealogy", Genealogy)

export const redirect = (link, data) => {

    if (!routes.has(link)) {
        console.log(`redirect to ${link} failed.`)
        return
    }

    const { dispatch } = useRegistry()

    dispatch("SPINNER_ON")
    
    const body = document.getElementById("body")
    body.innerHTML = ''

    const component = routes.get(link)
    
    if (data) body.appendChild(component(data))
    else body.appendChild(component())
    
    setTimeout(function() { 
        dispatch("SPINNER_OFF")
    }, 1000)
    
}
