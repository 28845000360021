import { Form
    , Container
    , Section
    , Heading
    , TextInput
    , SaveButton
    , useRegistry } from "../components/utils.js"

export const Forum = ({member}) => {

    const board = document.createElement("div")
    board.className = "container"

    board.appendChild(Container([Section({left:Form(
        [ Heading("title", "Forum", "1")
        , TextInput("name", "Name", "")
        , TextInput("created", "Created", "", "date")
        , SaveButton(getSave(member)) ])})]))
    
    return board
}

const postForm = async (member) => {
    const name = document.getElementById('name').value
    const created = document.getElementById('created').value

    var data = new FormData()
    data.append('name', name)
    data.append('created', created)
    data.append('member', member)

    resp = await fetch('/api/forums', {
        method: 'POST',
        body: data
    })
    json = await resp.json()
    return json.data
}

const getSave = (member) => {
    const save = () => {
        const { dispatch } = useRegistry()
        dispatch("SPINNER_ON")
        postForm(member)
            .then((resp)=> dispatch("SPINNER_OFF"))   
            .catch((err)=> dispatch("SPINNER_OFF")) 
    }
    return save
}





