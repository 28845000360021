
export const getForums = async ()=> {
    const resp = await fetch('/api/forums')
    const json = await resp.json()
    return json.data
}

export const postForum = async ({id, name, member, created}) => {

    const data = new FormData()
    data.append('id', id)
    data.append('name', name)
    data.append('member', member)
    data.append('created', created)

    const resp = postData(data)

    return resp
}

export const postData = async (data) => {

    const resp = await fetch('/api/forums', {
        method: 'POST',
        body: data
    })

    const json = await resp.json()
    return json.data
}
