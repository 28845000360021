
let members = []

export const getMember = async (id)=>{
    return (await getMembers()).find((x)=>x.id==id)
}

export const getMembers = async ()=>{
    if (members.length!=0) return members
    const resp = await fetch('/api/members')
    const json = await resp.json()
    return members = json.data
}

export const postMember = async ({id, name}) => {

    const data = new FormData()
    data.append('id', id)
    data.append('name', name)

    const resp = postData(data)

    getMember(id).name = name

    return resp
}

export const postData = async (data) => {

    const resp = await fetch('/api/members', {
        method: 'POST',
        body: data
    })

    const json = await resp.json()
    return json.data
}
