import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css' // needs additional webpack config!
import 'bootstrap/dist/js/bootstrap.bundle.js'

import '../css/site.css'
import { App } from './app.js'

window.addEventListener("load", () => {
    const root = document.getElementById("root")
    App(root)
})