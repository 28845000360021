import { Form
    , Container
    , Section
    , Heading
    , TextInput
    , SelectInput
    , SaveButton
    , useRegistry } from "../components/utils.js"
import { getAccountId } from "../components/auth.js"
import { postEvent } from "../services/events.js"
import { redirect } from "../components/router.js"

export const Event = () => {

    const board = document.createElement("div")
    board.className = "container"

    board.appendChild(Container([Section({left:Form(
        [ Heading("desc", "Event", "1")
        , TextInput("title", "Title", "")
        , TextInput("start", "Start", "", "date")
        , SelectInput("repeat", "Repeat every", "none", 
            [{value:'none', text:'None'},
             {value:'year', text:'Year'}])
        , SaveButton(getSave()) ])})]))
    
    return board
}

const postForm = async () => {

    const result = await postEvent({
        member: getAccountId(),
        title : document.getElementById('title').value,
        start : document.getElementById('start').value,
        repeat:  document.getElementById('repeat').value    
    })
    if (result.status == 'NOK') throw result.message

}

const getSave = () => {
    const save = () => {
        const innerSave = async ()=> {
            const { dispatch } = useRegistry()
            try {
                dispatch("SPINNER_ON")
                try {
                    await postForm()
                    redirect("events")
                }
                finally {
                    dispatch("SPINNER_OFF")
                }    
            }
            catch(err) {
                alert(err)
            }
        }
        innerSave()
    }
    return save
}





