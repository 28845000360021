export const getEvents = async (start, end)=>{
    const resp = await fetch(`/api/events?start=${start}&end=${end}`)
    const json = await resp.json()
    return json.data
}

export const postEvent = async ({member, title, start, repeat}) => {

    var data = new FormData()
    data.append('member', member)
    data.append('title', title)
    data.append('start', start)
    data.append('repeat', repeat)

    const resp = await fetch('/api/events', {
        method: 'POST',
        body: data
    })
    const json = await resp.json()
    return json
}

export const deleteEvent = async (id) => {
    const resp = await fetch(`/api/events?id=${id}`, {method: 'DELETE'})
    const json = await resp.json()
    return json
}
