import { Heading } from "../components/utils.js"
import { getForums } from "../services/forums.js"
import { redirect } from "../components/router.js"

export const Forums = (member) => {
    
    const board = document.createElement('div')
    board.className = "container my-3"

    const flex = document.createElement('div')
    flex.className = "d-flex"

    const heading = Heading("first-title", "Forums", "4")
    heading.className = "d-inline me-auto" //push the sibling far right

    const newforum = PillButton(member)

    flex.appendChild(heading)
    flex.appendChild(newforum)
    board.appendChild(flex)

    mountCardList().then((table)=> {
        board.appendChild(table)
    })

    return board
}

const PillButton = (member) => {

    const el = document.createElement("button")
    
    el.type="button"
    el.className='btn btn-xts btn-primary'
    el.textContent = "New Forum"
    el.addEventListener("click", (e) => {
        e.preventDefault()
        redirect("forum", {member})  
    })

    return el
}

const CardList = (items) => {
    const el = document.createElement("div")
    el.className = "d-grid gap-2 col-8 mx-auto my-3"

    items.forEach((item)=>el.appendChild(item))

    return el
}

const Card = (id, name) => {

    const card = document.createElement("div")
    card.className="card"

    const body = document.createElement("div")
    body.className="card-body"

    const a = document.createElement("a")
    a.href="#"
    a.className="d-inline bi bi-play btn btn-primary"
    a.addEventListener("click", (e)=> {
        e.preventDefault()
        redirect("comments", {id, name}) 
    })

    const heading = Heading('title', name, '5')
    heading.className = "d-inline mx-2"

    body.appendChild(a)
    body.appendChild(heading)

    card.appendChild(body)

    return card
}

const mountCardList = async () => {
    const members = await getForums()
    return CardList(members.map((m)=>Card(m.id, m.name)))
}