import { Menu } from "../components/menu.js"
import { Body } from "../components/body.js"

export const Layout = () => {

    const board = document.createElement("div")
  
    const menu = Menu()

    const body = Body()

    board.appendChild(menu)
    board.appendChild(body)

    return board
}