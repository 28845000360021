import { PublicClientApplication } from "@azure/msal-browser"

const msalConfig = {
  auth: {
      clientId: 'fb3daf69-e2d6-4124-8746-bc15e3fdd9eb',
      authority: 'https://login.microsoftonline.com/a320bd3e-d2c1-4a8d-82ff-1079f9cf3dab',
      redirectUri: 'https://albairat.net/.auth/login/aad/callback'
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

export const getAccountId = () => {
  try {
    const account = getAccount()
    if (account) return account.localAccountId
    else return ''
  }
  catch(error) {
    return ''
  }
}

export const getAuthenticated = () => {
  try {
    return getAccount() != null
  }
  catch(error) {
    return false
  }
}

export const getAccount = (instance=msalInstance) => {
  const accounts = instance.getAllAccounts()
  const account = accounts && accounts.length > 0 ? accounts[0] : null
  if (!account) return null
  return account
}

export const getAccessToken = async (instance=msalInstance) => {
  account = getAccount()
  const tokenResponse = await instance.acquireTokenSilent({
    scopes: msalConfig.api.scopes,
    account: account,
  })
  return tokenResponse?.accessToken
}

export const login = async (instance=msalInstance) => {
    return await instance.loginPopup({scopes:[]})
}

export const logout = async (instance=msalInstance) => {
    return await instance.logoutPopup({})
}
