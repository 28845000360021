export const Heading = (id, text, level) => {
  
  level ||= "1"
  level = ("123456".indexOf(level) == -1) ? "1" : level

  const h = document.createElement(`h${level}`)
  h.textContent = text 

  return h
}

export const TextInput = (id, label, text, type) => {
  
  type ||= "text"
  
  const board = document.createElement("div")
  board.className = "mb-3"

  if (label) {
    const lbl = document.createElement("label")
    lbl.setAttribute("for", id)
    lbl.className = "form-label"
    lbl.textContent = label 
  
    board.appendChild(lbl)  
  }

  const npt = document.createElement("input")
  npt.id = id
  npt.type = type
  npt.className = "form-control"
  npt.value = text 

  board.appendChild(npt)

  return board
}

export const CheckBoxInput = (id, label, checked) => {
   
  const board = document.createElement("div")
  board.className = "form-check my-1" //mb-3

  const lbl = document.createElement("label")
  lbl.setAttribute("for", id)
  lbl.className = "form-check-label"
  lbl.textContent = label 

  board.appendChild(lbl)

  const npt = document.createElement("input")
  npt.id = id
  npt.type = 'checkbox'
  npt.className = "form-check-input"
  npt.checked = checked 

  board.appendChild(npt)

  return board
}

export const SelectInput = (id, label, value, options) => {
    
  const board = document.createElement("div")
  board.className = "mb-3"

  if (label) {
    const lbl = document.createElement("label")
    lbl.setAttribute("for", id)
    lbl.className = "form-label"
    lbl.textContent = label 
  
    board.appendChild(lbl)  
  }

  const npt = document.createElement("select")
  npt.id = id
  npt.className = "form-select"
  npt.value = value 

  options.forEach(({value, text})=>{ 
    const opt = document.createElement("option")
    opt.setAttribute('value', value)
    opt.textContent = text
    npt.appendChild(opt)
  })

  board.appendChild(npt)

  return board
}


export const Anchor = (id, label, click) => {
  const a = document.createElement("a")
  a.id=id
  a.href="#"
  a.textContent=label 
  if (click) a.onclick = (e) => { 
    e.preventDefault() 
    click() 
  } 
  return a
}

export const SaveButton = (click) => {
  const a = document.createElement("button")
  a.className="btn btn-success"
  a.textContent="Save"
  a.setAttribute("type", "submit") 
  if (click) a.onclick = (e) => { 
    e.preventDefault() 
    click() 
  } 
  return a
}

export const Container = (components) => {
  const container = document.createElement("div")
  container.className = "container"
  components.forEach((component) => container.appendChild(component)) 
  return container
}

export const Section = ({left, right }) => {
  const row = document.createElement("div")
  row.className = "row"

  const Col = (component) => {
    const col = document.createElement("div")
    col.className = "col"
    if (component instanceof HTMLElement) 
      col.appendChild(component) 
    else col.appendChild(document.createElement("span"))
    return col
  }

  left = Col(left)
  right = Col(right)

  row.appendChild(left)
  row.appendChild(right)
   
  return row
}

export const Form = (components) => {
  const form = document.createElement("form")
  components.map((component) => form.appendChild(component)) 
  return form
}

export const Search = (value) => {
  const { publish, dispatch } = useRegistry()

  publish("SEARCH")

  const form = document.createElement("form")
  form.role="search"

  const input = document.createElement("input")
  input.type="search"
  input.className="form-control"
  input.placeholder="Search"
  input.value=value
  input.addEventListener("keydown", (e) => {
    if (e.code === "Enter") {
      e.preventDefault()
      const value = e.target.value
      dispatch("SEARCH", value)  
    }
  });

  input.setAttribute("aria-label", "Search")

  form.appendChild(input)

  return form
}

export const getApiData = async ({ uri, accessToken, initData }) => {
  const headers = accessToken ? new Headers() : null
  headers && headers.append("Authorization", `Bearer ${accessToken}`)
  const options = headers
    ? { method: "GET", headers: headers }
    : { method: "GET" }
  const resp = uri ? await fetch(uri, options) : null
  const dt = resp ? await resp.json() : null
  return dt || { status: "OK", message: "url not provided", data: initData }
}

export const postApiData = async (url, accessToken, data = {}) => {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")
  if (accessToken) headers.append("Authorization", `Bearer ${accessToken}`)
  // Default options are marked with *
  const resp = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "same-origin", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headers, // 'Content-Type': 'application/x-www-form-urlencoded', application/json
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return await resp.json() // parses JSON response into native JavaScript objects
}

export const getUser = async () => {
  try {
    let resp = await fetch("/.auth/me")
    let data = await resp.json()
    console.log("getUser.data: ", data)
    let _user = data?.clientPrincipal?.userDetails
    if (_user) return { email: _user }
    return null
  }
  catch(error) {
    console.log("getUser.error: ", error)
    return null
  }
  
}

export const Auth = (authenticated, clickLogin, clickLogout, clickSettings) => {

  const nav = document.createElement("ul")
  nav.className = "navbar-nav"

  const dropdown = document.createElement("li")
  dropdown.className = "nav-item dropdown"

  const anchor = document.createElement("a")
  anchor.className = "nav-link dropdown-toggle"
  anchor.href="#"
  anchor.setAttribute("data-bs-toggle", "dropdown")
  anchor.setAttribute("aria-expanded", "false")

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  svg.setAttribute("width", "32")
  svg.setAttribute("height", "32")
  svg.setAttribute("fill", "currentColor")
  svg.setAttribute("viewBox", "0 0 32 32")

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path")
  path.setAttribute("d", "M 16 16 a 6 6 90 1 0 0 -12 a 6 6 90 0 0 0 12 Z m 4 -6 a 4 4 90 1 1 -8 0 a 4 4 90 0 1 8 0 Z m 8 16 c 0 2 -2 2 -2 2 H 6 s -2 0 -2 -2 s 2 -8 12 -8 s 12 6 12 8 Z m -2 -0.008 c -0.002 -0.492 -0.308 -1.972 -1.664 -3.328 C 23.032 21.36 20.578 20 16 20 c -4.58 0 -7.032 1.36 -8.336 2.664 c -1.356 1.356 -1.66 2.836 -1.664 3.328 h 20 Z")

  const menu = document.createElement("ul")
  menu.className="dropdown-menu dropdown-menu-end"

  const AuthMenuItem = (title, click, classes) => { 
    const item = document.createElement("li")
    const item_inner = document.createElement("div")
    item_inner.className="dropdown-item d-grid"

    const d = document.createElement("a")
    d.href= (click.constructor.name == "String") ? click : "#"
    d.textContent = title
    d.className= `btn ${classes}` 
    
    if (click.constructor.name == "Function") {
      d.onclick = (e) => {
        e.preventDefault()
        if (click) click()
      }  
    }

    item_inner.appendChild(d)
    item.appendChild(item_inner)

    return item
  }

  const items = (authenticated) ? 
  [AuthMenuItem("Settings", clickSettings, "btn-primary")
  , AuthMenuItem("Logout", clickLogout, "btn-danger")] 
  : [AuthMenuItem("Login", clickLogin, "btn-success")] 
    

  items.forEach((x)=> menu.appendChild(x))

  svg.appendChild(path)
  anchor.appendChild(svg)
  dropdown.appendChild(anchor)
  dropdown.appendChild(menu)
  nav.appendChild(dropdown)
  return nav
}


export function createTable(id, columnNames, numRows, cellDataProvider) {
    // Create a table element
    var table = document.createElement('table');
  
    table.id=id

    // Create a table header row
    var thead = document.createElement('thead');
    var headerRow = document.createElement('tr');
  
    // Add column names to the header row
    columnNames.forEach(function(columnName) {
      var th = document.createElement('th');
      th.textContent = columnName;
      headerRow.appendChild(th);
    });
  
    // Append the header row to the table header
    thead.appendChild(headerRow);
  
    // Append the table header to the table
    table.appendChild(thead);
  
    // Create a table body
    var tbody = document.createElement('tbody');
  
    // Create rows with empty cells
    for (var i = 0; i < numRows; i++) {
      var row = document.createElement('tr');
      for (var j = 0; j < columnNames.length; j++) {
        var cell = document.createElement('td');
        const content = (cellDataProvider)? cellDataProvider(i, j): '';
        if (content instanceof HTMLElement) cell.appendChild(content)
        else cell.textContent = content   // You can set default text if needed
        row.appendChild(cell);
      }
      // Append the row to the table body
      tbody.appendChild(row);
    }
  
    // Append the table body to the table
    table.appendChild(tbody);
  
    return table;
  }

/*
Implementation of the observer pattern
*/
const registry = new Map()

const publish = (name) => {
  if (registry.has(name)) return
  registry.set(name, { actions: [] })
}

const subscribe = (name, action) => {
  if (!registry.has(name)) return
  const obj = registry.get(name)
  obj.actions.push(action)
  console.log("subscribe")
}

const unsubscribe = (name, action) => {
  if (!registry.has(name)) return
  const obj = registry.get(name)
  obj.actions = obj.actions.filter((act) => act !== action)
  console.log("unsubscribe")
}

const dispatch = (name, data) => {
  if (!registry.has(name)) return
  const obj = registry.get(name)
  obj.actions.forEach((action) => {
    action?.(data)
  })
}

export const useRegistry = () => {
  return { publish, subscribe, unsubscribe, dispatch }
}