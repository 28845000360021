import { redirect } from "../components/router.js"
import { getAccountId } from '../components/auth.js'
import { Heading } from "../components/utils.js"

export const Album = ({member, container, name}) => {

    const board = document.createElement('div')
    board.className = "container"

    const heading = Heading("title", `Photos of ${name}`, "1")
    board.appendChild(heading)

    mountImages(member, container).then((images)=>board.appendChild(images))

    return board
}

const AddPhoto = (member, container) => {
    const a = document.createElement('a')
    a.href="#"
    a.className = "d-flex align-items-center justify-content-center"
    a.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="59" height="64" viewBox="0 0 59 64" xml:space="preserve">
        <g transform="matrix(3.65 0 0 3.65 29.17 29.17)"  >
            <g style=""   >
                <g transform="matrix(1 0 0 1 0 0)"  >
                    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: evenodd; opacity: 1;"  transform=" translate(-8, -8)" d="M 8 5.5 a 0.5 0.5 0 0 1 0.5 0.5 v 1.5 H 10 a 0.5 0.5 0 0 1 0 1 H 8.5 V 10 a 0.5 0.5 0 0 1 -1 0 V 8.5 H 6 a 0.5 0.5 0 0 1 0 -1 h 1.5 V 6 a 0.5 0.5 0 0 1 0.5 -0.5" stroke-linecap="round" />
                </g>
                <g transform="matrix(1 0 0 1 0 -0.5)"  >
                    <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  transform=" translate(-8, -7.5)" d="M 4.406 3.342 A 5.53 5.53 0 0 1 8 2 c 2.69 0 4.923 2 5.166 4.579 C 14.758 6.804 16 8.137 16 9.773 C 16 11.569 14.502 13 12.687 13 H 3.781 C 1.708 13 0 11.366 0 9.318 c 0 -1.763 1.266 -3.223 2.942 -3.593 c 0.143 -0.863 0.698 -1.723 1.464 -2.383 z m 0.653 0.757 c -0.757 0.653 -1.153 1.44 -1.153 2.056 v 0.448 l -0.445 0.049 C 2.064 6.805 1 7.952 1 9.318 C 1 10.785 2.23 12 3.781 12 h 8.906 C 13.98 12 15 10.988 15 9.773 c 0 -1.216 -1.02 -2.228 -2.313 -2.228 h -0.5 v -0.5 C 12.188 4.825 10.328 3 8 3 a 4.53 4.53 0 0 0 -2.941 1.1 z" stroke-linecap="round" />
                </g>
            </g>
        </g>
    </svg>`
    a.onclick=(e)=>{
        e.preventDefault()
        redirect("photo", {member, container})
    }
    return a
}

const id = (photo) => {
    //photo=32132654654989865498654.jpg
    const splt = photo.split('.')
    return `id${splt[0]}`
} 

const srcImg = (container, photo) => {
    return `https://photomapdatastore.blob.core.windows.net/${container}/${photo}`
} 

const Modal = (container, photo) => {
    
    const board = document.createElement('div')
    board.className="modal fade" 
    board.id=id(photo) 
    board.tabIndex="-1" 
    board.setAttribute('aria-labelledby', "exampleModalLabel")
    board.setAttribute('aria-hidden', "true")

    const modal = document.createElement('div')
    modal.className="modal-dialog"

    board.appendChild(modal)

    const content = document.createElement('div')
    content.className="modal-content"
    
    modal.appendChild(content)

    const img = document.createElement('img')
    img.src=srcImg(container, photo)
    img.className="img-thumbnail"

    content.appendChild(img)

    return board
}

const Image = (container, photo, label)=>{

    const board = document.createElement('div')

    const a = document.createElement('a')
    a.href="#" 
    a.type="button" 
    a.setAttribute('data-bs-toggle', "modal") 
    a.setAttribute('data-bs-target', "#"+id(photo))

    const img = document.createElement('img')
    img.src=srcImg(container, photo)
    img.className="d-block img-thumbnail mx-auto"

    a.appendChild(img)

    const lbl = document.createElement('label')
    lbl.textContent=label
    lbl.className="d-block text-center"

    board.appendChild(a)
    board.appendChild(lbl)
    board.appendChild(Modal(container, photo))

    return board
} 

const Images = (images)=>{

    const len = images.length
    const rows = Math.floor(len / 4) + ((len % 4) ? 1:0)

    const board = document.createElement('div')
    board.className = "container"

    for (let r = 0; r < rows; r++) {
        const row = document.createElement('div')
        row.className = "row"
    
        for (let c = 0; c < 4; c++) {
            const col = document.createElement('div')
            col.className = "col d-flex align-items-center justify-content-center"

            let i = r*4 + c
            if (i < images.length) col.appendChild(images[i])

            row.appendChild(col)
        }

        board.appendChild(row)    
    }

    return board
}

const getPhotos = async (member) => {
    const resp = await fetch(`/api/photos?member=${member}`)
    const json = await resp.json()
    return json.data 
}

const mountImages = async (member, container) => {
    const data = await getPhotos(member)
    const images = data.map((x)=>{
        return Image(x.container, x.photo, x.description)
    })
    const addPhoto = (getAccountId()==member)?[AddPhoto(member, container)]:[]
    return Images(addPhoto.concat(images))
}