import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'

import { redirect } from '../components/router.js'
import { getEvents, deleteEvent } from '../services/events.js'
import { useRegistry } from '../components/utils.js'

export const Events = () => {
    
    const board = document.createElement('div')
    board.className="container"

    const calendar = new Calendar(board, {
        plugins: [ interactionPlugin, dayGridPlugin, listPlugin, bootstrap5Plugin ],
        themeSystem: 'bootstrap5',
        headerToolbar: {
          left: 'prev,next today addEvent',
          center: 'title',
          right: 'dayGridMonth,listWeek'
        },
        initialDate: new Date(Date.now()).toJSON(),
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        height: '100%',
        customButtons: {
            addEvent: {
              text: 'Add Event',
              click: function() {
                redirect("event")            
              }
            }
        },
        events: function(info, successCallback, failureCallback) {
            const innerGetEvents = async ()=> {
                const { dispatch } = useRegistry()
                dispatch("SPINNER_ON")
                try {
                    const start = info.start.toJSON().split('T')[0]
                    const end = info.end.toJSON().split('T')[0]
                    const events = (await getEvents(start, end))
                        .map((x)=> ({ title:x.title, start:x.start, id:x.id }))
                    successCallback(events)
                }
                catch(err) {
                    failureCallback(err)
                }
                finally {
                    dispatch("SPINNER_OFF")
                }
            }
            innerGetEvents()
        },
        eventClick: function(info) {
            if (confirm('Do you want to delete event?')) {
                deleteEvent(info.event.id)
                calendar.refetchEvents()
            }
        }

    })
    
    setTimeout(function() {
        calendar.render()
    }, 0)
    
    return board
}