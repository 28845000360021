import { Layout } from "./components/layout.js"
import { redirect } from "./components/router.js"

export const App = (root) => {

    root.innerHTML = ""

    root.appendChild(Layout())

    redirect("home")

}
