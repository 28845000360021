// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* html {
    @media only screen and (max-width: 768px) {
        font-size:30px;
    }
} */

.form-check {
    padding-left: 0em;
}
  
.form-check .form-check-input {
    float: none;
    margin-left: 0.5em;
}

img.img-full {
	height: 100%;
	width: 100%;
    max-width: 100%;
	vertical-align: middle;
    overflow: hidden;
}

.btn-xts {
    --bs-btn-padding-y: .25rem;
    --bs-btn-padding-x: .50rem;
    --bs-btn-font-size: .75rem;
}

.btn-xtl {
    @media (width <= 500px) {
        --bs-btn-padding-y: 1rem;
        --bs-btn-padding-x: 2rem;
        --bs-btn-font-size: 3rem;
    }
}

.comment {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.user-comment {
    background-color: #5cb85c; /* Green for current user */
    color: white;
    text-align: right;
}

.other-comment {
    background-color: #f0f0f0; /* Light gray for others */
    text-align: left;
}

.comments {
    height: 70%;
    overflow-x: overlay;
}

.message-box {
    position: fixed;
    bottom: 0;
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./css/site.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;CACC,YAAY;CACZ,WAAW;IACR,eAAe;CAClB,sBAAsB;IACnB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI;QACI,wBAAwB;QACxB,wBAAwB;QACxB,wBAAwB;IAC5B;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB,EAAE,2BAA2B;IACtD,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,UAAU;AACd","sourcesContent":["/* html {\n    @media only screen and (max-width: 768px) {\n        font-size:30px;\n    }\n} */\n\n.form-check {\n    padding-left: 0em;\n}\n  \n.form-check .form-check-input {\n    float: none;\n    margin-left: 0.5em;\n}\n\nimg.img-full {\n\theight: 100%;\n\twidth: 100%;\n    max-width: 100%;\n\tvertical-align: middle;\n    overflow: hidden;\n}\n\n.btn-xts {\n    --bs-btn-padding-y: .25rem;\n    --bs-btn-padding-x: .50rem;\n    --bs-btn-font-size: .75rem;\n}\n\n.btn-xtl {\n    @media (width <= 500px) {\n        --bs-btn-padding-y: 1rem;\n        --bs-btn-padding-x: 2rem;\n        --bs-btn-font-size: 3rem;\n    }\n}\n\n.comment {\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 8px;\n}\n\n.user-comment {\n    background-color: #5cb85c; /* Green for current user */\n    color: white;\n    text-align: right;\n}\n\n.other-comment {\n    background-color: #f0f0f0; /* Light gray for others */\n    text-align: left;\n}\n\n.comments {\n    height: 70%;\n    overflow-x: overlay;\n}\n\n.message-box {\n    position: fixed;\n    bottom: 0;\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
