import { redirect } from "../components/router.js"
import { Anchor, createTable } from "../components/utils.js"

import arganurl from '../../img/argan.jpg'


export const Home = () => {
    
    const argan = document.createElement('img')
    argan.alt="#"
    argan.src=arganurl
    argan.className="img-full"

    return argan
}