import { Heading } from '../components/utils.js' 
import { getComments, postComment } from '../services/comments.js'
import { getAccountId } from '../components/auth.js'

export const Comments = (forum) => {
    const board = document.createElement('div')
    board.className = "container"

    const heading = Heading("first-title", `Forum: ${forum.name}`, "4")

    const comments = Commentz(forum.id)

    const message = MessageBox(forum.id, comments)

    board.appendChild(heading)

    board.appendChild(comments)

    board.appendChild(message)

    return board
}

const Commentz = (forum) => {
    const comments = document.createElement('div')
    comments.className="comments form-control"

    const compare = (a, b) => {
        if (a.created < b.created) return -1
        else if (a.created > b.created) return 1
        else return 0
    }

    const mountComments = async () => {
        const member = getAccountId()
        const lst = (await getComments(forum)).sort(compare)
        const items = lst.map((x)=> {
            return (x.member==member) ? Comment(x.content, x.created) : Comment(x.content, x.created, x.memberName)
        })
        items.forEach((x)=>comments.appendChild(x))
    }

    mountComments()

    return comments
}

const MessageBox = (forum, comments) => {
    const box = document.createElement('div')
    box.className = "d-flex my-3 message-box"

    const txt = document.createElement("input")
    txt.id = "txt"
    txt.type = "text"
    txt.className = "d-inline me-1 form-control"

    box.appendChild(txt)

    const a = document.createElement("a")
    a.href="#"
    a.className="d-inline bi bi-play btn btn-primary"
    a.addEventListener("click", (e)=> {
        e.preventDefault()
        if (!txt.value) return
        comments.appendChild(Comment(txt.value, new Date(Date.now()).toJSON()))
        comments.scrollTo(0, comments.scrollHeight)
        postComment({member:getAccountId()
            , forum:forum
            , content:txt.value
            , created:(new Date()).toJSON()})
            .then((resp) => {})
            .catch((resp) => {})
    })

    box.appendChild(a)
    return box
}

const Comment = (message, created, memberName) => {
    const commentClass = (memberName)? 'other-comment' : 'user-comment'
    const textContent = (memberName)? `${memberName} : ${message}` : message
    
    const board = document.createElement('div')

    const comment = document.createElement('div')
    comment.classList.add('comment', commentClass)
	
	const span_0 = document.createElement('span')
	span_0.style.display = 'block'
    span_0.textContent = textContent
    
	comment.appendChild(span_0)
	
    const span_1 = document.createElement('span')
	span_1.style.float='right'
	span_1.style.height ='0.7rem'
	span_1.style.fontSize ='0.5rem'
    span_1.textContent = formatDatetime(created)

    comment.appendChild(span_1)
    
	board.appendChild(comment)

    return board
}

const formatDatetime = (dtISO) => {
	const split = dtISO.split('T')
	const split_1 = split[1].split(':')
	
	const date = split[0]
	const hours = split_1[0]
	const minutes = split_1[1]
	
	return `${date} ${hours}:${minutes}`
}