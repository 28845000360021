import { getAccountId } from '../components/auth.js'
import { getMember, postMember } from '../services/members.js'
import { Form
    , Container
    , Section
    , Heading
    , TextInput
    , SaveButton
    , useRegistry } from "../components/utils.js"

const { dispatch } = useRegistry()

export const Settings = () => {

    const id = getAccountId()

    const board = document.createElement("div")
    board.className = "container"

    getForm(id).then(({name})=> {
        board.appendChild(Container([Section({left:Form(
            [ Heading("title", "Settings", "1")
            , TextInput("name", "Name", name)
            , SaveButton(getSave(id)) ])})]))    
    })
    
    return board
}

const getForm = async (id) => {
    dispatch("SPINNER_ON")
    try {
        return await getMember(id)
    }
    finally {
        dispatch("SPINNER_OFF")
    }
}

const postForm = async (id) => {
    const name = document.getElementById('name').value
    return await postMember({id, name})
}

const getSave = (member, store_container) => {
    const save = () => {
        dispatch("SPINNER_ON")
        postForm(member, store_container)
            .then((resp)=> dispatch("SPINNER_OFF"))   
            .catch((err)=> dispatch("SPINNER_OFF")) 
    }
    return save
}