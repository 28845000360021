import { useRegistry } from "./utils.js"

export const Body = () => {

    const { publish, subscribe, unsubscribe, dispatch } = useRegistry()

    publish("SPINNER_ON")
    publish("SPINNER_OFF")

    subscribe("SPINNER_ON", ()=>{
        const spinner = document.getElementById('spinner')
        spinner.innerHTML = ''
        spinner.appendChild(Spinner())
    })
    subscribe("SPINNER_OFF", ()=>{
        const spinner = document.getElementById('spinner')
        spinner.innerHTML = ''
    })

    const main = document.createElement("main")

    const body = document.createElement("div")
    body.id="body"

    const spinner = document.createElement("div")
    spinner.id="spinner"

    main.appendChild(spinner)
    main.appendChild(body)
    
    return main
}

const Spinner = () => {

    const board = document.createElement("div")
    board.style.zIndex=1000
    board.style.position='absolute'
    board.style.top='45%'
    board.style.left='45%'

    const div = document.createElement("div")
    div.className='d-block spinner-border mx-auto'
    div.role='status'

    const span = document.createElement("span")
    span.className='sr-only m-2'
    span.textContent='Loading...'

    board.appendChild(div)
    board.appendChild(span)

    return board
}